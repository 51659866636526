import React, { useState, useEffect } from 'react';
import '../styles/style.css';
import logoScoreHigh from '../resources/images/logoScoreHigh.svg';
import logoScoreMiddle from '../resources/images/logoScoreMiddle.svg';
import logoScoreLow from '../resources/images/logoScoreLow.svg';
import { useLocation } from 'react-router-dom';

function Scorepage() {
    const predictionScore = useLocation().state.municipality.voorspellingScore;

    const [logoScore, setLogoScore] = useState('');

    useEffect(() => {
        function setBackgroundColor(predictionScore) {
            const background = document.getElementsByClassName("scorepage")[0];

            if (background) {
                background.classList.remove("bg-75-100", "bg-50-74", "bg-25-49", "bg-1-24", "bg-0");

                if (predictionScore > 74) {
                    background.classList.add("bg-75-100");
                    setLogoScore(logoScoreHigh);
                } else if (predictionScore > 49 && predictionScore < 75) {
                    background.classList.add("bg-50-74");
                    setLogoScore(logoScoreHigh);
                } else if (predictionScore > 24 && predictionScore < 50) {
                    background.classList.add("bg-25-49");
                    setLogoScore(logoScoreMiddle);
                } else if (predictionScore > 0 && predictionScore < 25) {
                    background.classList.add("bg-1-24");
                    setLogoScore(logoScoreMiddle);
                } else {
                    background.classList.add("bg-0");
                    setLogoScore(logoScoreLow);
                }
            }
        }

        setBackgroundColor(predictionScore);
    }, [predictionScore]);


    return (
        <div className="scorepage">
            <div className="content-scorepage">
                <h1 className="title padding-top10"><img src={logoScore} className="logo" alt="logo" />{useLocation().state.municipality.gemeente}</h1>
                <h2 className="score row1">{useLocation().state.municipality.voorspellingScore}</h2>
                <h3 className="subtitle ">WAT BETEKENT DEZE SCORE?</h3>
                <h5 className="description row2">De veiligheidsscore die u ziet, is gebaseerd op drie belangrijke elementen: criminaliteit in de wijk, overlast in de wijk en input van alle gebruikers. Hoe hoger de score, hoe veiliger de omgeving doorgaans wordt beschouwd. Dit betekent dat een hogere score aangeeft dat de locatie waarschijnlijk veiliger is op basis van de verzamelde gegevens. Deze score is bedoeld om u te helpen bij het nemen van weloverwogen beslissingen over uw veiligheid en welzijn.</h5>
            </div>
        </div>
    );
}

export default Scorepage;
