import '../styles/style.css';
import logo from '../resources/images/logoSafeSpace.svg'
import doubleArrow from '../resources/icons/doubleArrow.svg'
import infoIcon from '../resources/icons/infoIcon.svg'
import {Link} from 'react-router-dom'

function Homepage() {
    return (
      <div className="homepage">
        <div className="background"></div>
        <div className="content-homepage section">
          <div className="team-info">
            <Link to='/team'><img src={infoIcon} className="infoIcon" alt="infoIcon" /></Link>
          </div>
          <img src={logo} className="full-logo" alt="logo" />
          <Link to='/choice'><button className="button">Start</button></Link>
          <div className="scroll-to-info">
            <p className="description bold">Wat is Safe Space?</p>
            <img src={doubleArrow} className="doubleArrow" alt="doubleArrow" />
          </div>
        </div>
        <div className="content-info section">
            <h2 className="subtitle">Wat is Safe Space?</h2>
            <div className="text-box">
                <h3 className="description">Het Concept</h3>
                <p className="description">Safe Space is een (web)applicatie waarmee gebruikers kunnen inschatten hoe veilig ze zich zouden voelen in een specifieke buurt, met behulp van een nauwkeurig algoritme dat rekening houdt met hun opgegeven voorkeuren.</p>
                <h3 className="padding-top description">Onze Data</h3>
                <p className="description">Onze data wordt direct verstrekt door de Nederlandse overheid en omvatten meldingen van overlast en criminaliteit die bij de politie zijn binnengekomen. Het algoritme maakt gebruik van deze gegevens, die maandelijks worden bijgewerkt.</p>
                <h3 className="padding-top description">Berekeningen</h3>
                <p className="description">Ons geavanceerde algoritme is uitstekend in staat om te voorspellen hoe veilig iemand zich zal voelen op een bepaalde locatie, op basis van beschikbare gegevens en feedback van andere gebruikers.</p>
                <h3 className="padding-top description">Privacy</h3>
                <p className="description">Allereerst verzamelen we geen persoonlijke gegevens van onze gebruikers, waardoor hun voorkeuren anoniem worden verwerkt in het algoritme. Hierdoor is het onmogelijk om te achterhalen welke input afkomstig is van welke gebruiker.</p>
            </div>
        </div>
      </div>     
    );
}

export default Homepage;