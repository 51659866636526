import React, { useState } from 'react';
import axios from 'axios';
import '../styles/style.css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import iconChoice from '../resources/icons/iconChoice.svg'
import { Link } from 'react-router-dom'

function Choicepage() {
    //All values get set to 80 standard and can get updated with the useState.
    const [sliderValues, setSliderValues] = useState({
        verwardPersoon: 80,
        hangJongeren: 80,
        drugsEnAlcohol: 80,
        zwervers: 80,
        geluidshinder: 80,
        inbraak: 80,
        diefstal: 80,
        geweld: 80,
        moord: 80,
    });

    //Update the value based on the slider value.
    const handleSliderChange = (name, value) => {
        setSliderValues({ ...sliderValues, [name]: value });
    };

    //Send the values of the slider to the server so they can be pushed in the MongoDB.
    const handleSaveButtonClick = () => {
        axios.post('/api/save-sliders', { sliderValues: sliderValues })
            .then((response) => {
                console.log("POST request successful. Response:", response.data);
            })
            .catch((error) => {
                console.error("Error in POST request:", error);
            });
    };

    //Styling of the sliders.
    const trackStyle = {
        background: 'linear-gradient(to right, green, orange, red)',
        height: "1rem",
    };

    const customStyle = {
        height: "1.6rem",
        width: "1.6rem",
        border: "5px solid white",
        background: "rgba(0,0,0,0.5)"
    };

    const customHeight = {
        height: "2rem",
    };

    return (
        <div className="choice">
            <div className="background"></div>
            <div className="content-choice">
                <img src={iconChoice} className="icon-choice" alt="iconChoice" />
                <h3 className='description'>In hoeverre tasten de volgende zaken uw veiligheidsgevoel aan?</h3>
                <div className='text-box'>
                    <div className='colomn-box'>
                        <div className='column bold'>
                            <p>Verwarde personen</p>
                            <Slider
                                style={customHeight}
                                min={80}
                                max={100}
                                value={sliderValues.verwardPersoon}
                                onChange={(value) => handleSliderChange('verwardPersoon', value)}
                                railStyle={trackStyle}
                                trackStyle={{ backgroundColor: 'transparent' }}
                                handleStyle={customStyle}
                            />
                            <p>Hangjongeren</p>
                            <Slider
                                style={customHeight}
                                min={80}
                                max={100}
                                value={sliderValues.hangJongeren}
                                onChange={(value) => handleSliderChange('hangJongeren', value)}
                                railStyle={trackStyle}
                                trackStyle={{ backgroundColor: 'transparent' }}
                                handleStyle={customStyle}
                            />
                            <p>Drugs & Alcohol</p>
                            <Slider
                                style={customHeight}
                                min={80}
                                max={100}
                                value={sliderValues.drugsEnAlcohol}
                                onChange={(value) => handleSliderChange('drugsEnAlcohol', value)}
                                railStyle={trackStyle}
                                trackStyle={{ backgroundColor: 'transparent' }}
                                handleStyle={customStyle}
                            />
                            <p>Zwervers</p>
                            <Slider
                                style={customHeight}
                                min={80}
                                max={100}
                                value={sliderValues.zwervers}
                                onChange={(value) => handleSliderChange('zwervers', value)}
                                railStyle={trackStyle}
                                trackStyle={{ backgroundColor: 'transparent' }}
                                handleStyle={customStyle}
                            />
                            <p>Geluidshinder (van evenementen)</p>
                            <Slider
                                style={customHeight}
                                min={80}
                                max={100}
                                value={sliderValues.geluidshinder}
                                onChange={(value) => handleSliderChange('geluidshinder', value)}
                                railStyle={trackStyle}
                                trackStyle={{ backgroundColor: 'transparent' }}
                                handleStyle={customStyle}
                            />
                        </div>
                        <div className='column bold'>
                            <p>Inbraak & Overvallen</p>
                            <Slider
                                style={customHeight}
                                min={80}
                                max={100}
                                value={sliderValues.inbraak}
                                onChange={(value) => handleSliderChange('inbraak', value)}
                                railStyle={trackStyle}
                                trackStyle={{ backgroundColor: 'transparent' }}
                                handleStyle={customStyle}
                            />
                            <p>Zakkenrollers en Straatroof</p>
                            <Slider
                                style={customHeight}
                                min={80}
                                max={100}
                                value={sliderValues.diefstal}
                                onChange={(value) => handleSliderChange('diefstal', value)}
                                railStyle={trackStyle}
                                trackStyle={{ backgroundColor: 'transparent' }}
                                handleStyle={customStyle}
                            />
                            <p>Geweld / Intimidatie</p>
                            <Slider
                                style={customHeight}
                                min={80}
                                max={100}
                                value={sliderValues.geweld}
                                onChange={(value) => handleSliderChange('geweld', value)}
                                railStyle={trackStyle}
                                trackStyle={{ backgroundColor: 'transparent' }}
                                handleStyle={customStyle}
                            />
                            <p>Moord</p>
                            <Slider
                                style={customHeight}
                                min={80}
                                max={100}
                                value={sliderValues.moord}
                                onChange={(value) => handleSliderChange('moord', value)}
                                railStyle={trackStyle}
                                trackStyle={{ backgroundColor: 'transparent' }}
                                handleStyle={customStyle}
                            />
                        </div>
                    </div>
                </div>
                <Link to='/location'><button className='button' onClick={handleSaveButtonClick}>Opslaan</button></Link>
            </div>
        </div>
    );
}

export default Choicepage;