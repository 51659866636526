import Homepage from './pages/Homepage';
import Scorepage from './pages/Scorepage';
import Choicepage from './pages/Choicepage';
import Locationpage from './pages/Locationpage';
import Teampage from './pages/Teampage';
import { HashRouter, Routes, Route } from 'react-router-dom';

//The routes that are available in the browser
function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/score" element={<Scorepage />} />
        <Route path="/choice" element={<Choicepage />} />
        <Route path="/location" element={<Locationpage />} />
        <Route path="/team" element={<Teampage />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
