import React, { useState, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import '../styles/style.css';
import '../styles/dropdown.css';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

function Locationpage() {
    const [municipalities, setMunicipalities] = useState([]);
    const [selectedMunicipality, setSelectedMunicipality] = useState(null);
    const [showError, setShowError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get('/api')
            .then((response) => {
                setMunicipalities(response.data);
            })
            .catch((error) => {
                console.log('Error fetching data: ', error);
            });
    }, []);

    //get the values from the selected municipality and set the values in the selectedMunicipality variable.
    const selectMunicipality = (selectedOption) => {
        const selectedMunicipality = municipalities.find(municipality => municipality.gemeente === selectedOption.value);
        setSelectedMunicipality(selectedMunicipality);
    };

    //button to go to the score page with the selected municipality.
    const handleButton = () => {
        if (selectedMunicipality !== null) {
            navigate(`/score`, { state: { municipality: selectedMunicipality } });
        }
        else {
            setShowError(true);
        }
    };

    return (
        <div className="locationpage">
            <div className="background"></div>
            <div className="content-locationpage">
                <h1 className="title">SAFE SPACE HEEFT UW LOCATIE NODIG</h1>
                <div className='dropdown'>
                    <Dropdown
                        options={municipalities.map(municipality => (municipality.gemeente))}
                        placeholder="Selecteer uw locatie"
                        onChange={selectMunicipality}
                    />
                </div>
                {showError && <div className="error-message bold">Selecteer eerst een gemeente</div>}
                <button className="button" onClick={handleButton}>Verder</button>
            </div>
        </div>
    );
}

export default Locationpage;
