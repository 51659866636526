import '../styles/style.css';
import { Link } from 'react-router-dom';
import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import PortretRobinS from '../resources/images/portretRobinS.png';
import PortretRobinM from '../resources/images/portretRobinM.png';
import PortretPaul from '../resources/images/portretPaul.png';
import PortretShad from '../resources/images/portretShad.png';
import PortretTim from '../resources/images/portretTim.png';
import kros from '../resources/icons/krosIcon.svg';

function Teampage() {

    const responsive = {
        0: { items: 1 },
        568: { items: 2 },
        1024: { items: 3 },
    };

    const items = [
        <div className="item"><img src={PortretRobinS} className="portret" alt="portret" /><p className="padding-top bold description">Robin Sitters</p><p>Web Developer</p></div>,
        <div className="item"><img src={PortretRobinM} className="portret" alt="portret" /><p className="padding-top bold description">Robin Muijs</p><p>Web Developer</p></div>,
        <div className="item"><img src={PortretPaul} className="portret" alt="portret" /><p className="padding-top bold description">Paul Kros</p><p>App Developer</p></div>,
        <div className="item"><img src={PortretTim} className="portret" alt="portret" /><p className="padding-top bold description">Tim Banh</p><p>Systeem Developer</p></div>,
        <div className="item"><img src={PortretShad} className="portret" alt="portret" /><p className="padding-top bold description">Shad Khan</p><p>Systeem Developer</p></div>,
    ];

    return (
        <div className="teampage">
            <div className="background"></div>
            <div className="team-info">
                <Link to='/'><img src={kros} alt='Kros' className='infoIcon'></img></Link>
            </div>
            <h2 className="subtitle">Het team</h2>
            <AliceCarousel
                mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy="alternate"
                infinite='true'
                disableDotsControls='true'
                disableButtonsControls='true'
                autoPlay='true'
                autoPlayInterval={1000}
            />
        </div>
    );
}

export default Teampage;